<template>
  <div class="app-branches">
    <branch-top-panel @create-branch="openModal" />
    <branch-table />
    <branch-modal :show.sync="modal.show" :title="modal.title" />
  </div>
</template>

<script>
import BranchTable from './branch-table/branch-table.vue';
import BranchModal from './branch-modal/branch-modal.vue';
import { FETCH_BRANCHES } from './shared/state/types/action_types';
import BranchTopPanel from './branch-top-panel/branch-top-panel.vue';

export default {
  name: 'Branch',
  components: { BranchTable, BranchModal, BranchTopPanel },
  data() {
    return {
      modal: {
        show: false,
        title: 'Добавить филиал',
      },
    };
  },
  async mounted() {
    await this.$store.dispatch(FETCH_BRANCHES, { page: 1 });
  },
  methods: {
    openModal() {
      this.modal.show = true;
    },
  },
};
</script>
